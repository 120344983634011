// 最外层盒子样式
%out-box {
  position: relative;
}

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}
.interact-index-container {
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  padding-bottom: 1.61rem;
  &-bread {
    @extend %inside-box;
    padding-top: 0.78rem;
    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    line-height: 0.2rem;
    display: flex;
    a {
      margin-right: 0.1rem;
    }
    span {
      color: #761f1e;
      // font-weight: 600;
      margin-left: 0.1rem;
    }
  }
  &-QRCode {
    @extend %inside-box;
    padding-top: 0.28rem;
    padding-bottom: 0.5rem;
    position: relative;
    img {
      width: 100%;
    }
    .wxCode {
      position: absolute;
      left: 0.7rem;
      top: 0.76rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  &-FAQ {
    @extend %inside-box;
    &-title {
      display: flex;
      img {
        width: 0.13rem;
        height: 0.29rem;
        &:nth-child(2) {
          width: 1.13rem;
          height: 0.28rem;
          margin: 0 0.25rem;
        }
      }
    }
    &-content {
      background-color: #fff;
      margin-top: 0.26rem;
      padding: 0.3rem;
      position: relative;
      &-headPic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      &-footerPic {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
      &-headPic,
      &-footerPic {
        img {
          width: 100%;
        }
      }
      &-data {
        font-size: 0.14rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        margin-bottom: 0.28rem;
      }
      &-list {
        ul li {
          border-bottom: 1px solid #d8d8d8;
          margin-top: 0.2rem;
          cursor: pointer;
          &:last-child {
            border-bottom: 0;
          }
        }
        &-title {
          display: flex;
          font-size: 0.18rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #273143;
          line-height: 0.26rem;
          // align-items: center;
          margin-bottom: 0.12rem;
          img {
            width: 0.09rem;
            height: 0.16rem;
            margin-right: 0.07rem;
            margin-top: 0.05rem;
          }
        }
        &-content {
          padding-left: 0.2rem;
          h3 {
            font-size: 0.14rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #56657f;
            line-height: 0.14rem;
            margin-top: 0.08rem;
            margin-bottom: 0.09rem;
          }
          p {
            width: 11.24rem;
            font-size: 0.14rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #273143;
            line-height: 0.28rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-indent: 2em;
          }
        }
      }
    }
    .isActive {
      color: #cd9e56;
    }
  }
}
